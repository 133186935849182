<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

     <!-- DIALOG ATTACHMENT -->
    <v-dialog
      v-model="dialog.attachment"
      persistent
      width="1000"
      scrollable>
      <v-card>
        <v-card-title>
          <span>Sisipkan Gambar</span>
          <v-spacer></v-spacer>
          <v-icon color="red" size="30" @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-col class="px-6 py-0">
          <v-tabs color="#F05326" v-model="tab" background-color="transparent">
            <v-tab class="text-capitalize"> Riwayat </v-tab>
            <v-tab class="text-capitalize"> URL gambar </v-tab>
            <v-tab class="text-capitalize"> Dari komputer </v-tab>
          </v-tabs>
        </v-col>
        <v-divider></v-divider>
        <v-card-text class="d-md-flex pa-0" style="height: 510px;">
          <v-col class="fill-height background pa-0 overflow-auto">
            <v-tabs-items class="transparent fill-height" v-model="tab">
              <v-tab-item>
                <v-card-text style="height:470px;overflow:auto;" class="pb-5">
                  <v-text-field
                    solo
                    filled
                    dense
                    flat
                    :hide-details="true"
                    placeholder="Search your audio by title . . ."
                    autocomplete="off"
                    v-model="searching"
                    color="#F05326"
                    v-on:keyup.enter="fetchRecents"
                    prepend-inner-icon="mdi-magnify">
                    <template v-if="searching" v-slot:append>
                      <v-icon color="#F05326" @click="searching = '';fetchRecents();">mdi-close</v-icon>
                    </template>
                  </v-text-field>
                  <v-list nav color="background" flat class="mt-3">
                    <v-list-item-group
                      mandatory
                      active-class="bordered-1"
                      v-model="selected">
                      <v-row>
                        <template>
                          <v-col v-for="recent in recents" :key="recent.id" cols="4"
                            :style="recent == recents[selected] ? 'opacity:1;border: 2px solid #F05326' : 'opacity:.3'"
                            class="rounded-lg">
                            <v-skeleton-loader type="image" v-if="$store.state.process.run"></v-skeleton-loader>
                            <v-list-item v-show="!$store.state.process.run" class="pa-0">
                              <v-img  
                                :src="recent.public_url"
                                :alt="recent.alt"
                                contain
                                aspect-ratio="1">
                                <template slot="placeholder">
                                  <v-row
                                    class="fill-height"
                                    no-gutters
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      color="#F05326">
                                    </v-progress-circular>
                                  </v-row> 
                                </template>
                              </v-img>
                            </v-list-item>
                            <v-list-item-subtitle class="text-wrap text-center">
                              {{ recent.title }}
                            </v-list-item-subtitle>
                          </v-col>
                        </template>
                        <template v-if="recents.length < 1">
                          <v-col> No recent media </v-col>
                        </template>
                      </v-row>
                    </v-list-item-group>
                    <div class="text-center mt-4">
                      <div class="mb-2">
                        Showing {{ recents.length }} from {{ recent.total }}
                      </div>
                      <v-btn
                        color="primary"
                        small
                        elevation="0"
                        @click="fetchRecents">
                        More
                      </v-btn>
                    </div>
                  </v-list>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text class="px-8 py-6">
                  <v-row>
                    <v-col>
                      <div class="subtitle-2 mb-2">Masukkan URL</div>
                      <v-text-field
                        outlined
                        dense
                        color="#F05326"
                        hide-details="auto"
                        autocomplete="off"
                        v-model="url"
                        @change="setFile">
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="$store.state.process.run">
                    <v-col>
                      <v-skeleton-loader type="image"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="!_.isEmpty(file[0]) && file[0].url != undefined">
                    <v-col>
                      <v-img class="rounded" :src="file[0].url"></v-img>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="errors">
                    <v-col>
                      <div class="text-center title color--text text--lighten-3">
                        {{ errors.message }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>
              <v-tab-item
                class="fill-height"
                style="position: relative"
                @dragover.prevent="draging = true"
                @dragleave="draging = false"
                @drop.prevent="setFile">
                <v-card-text class="fill-height d-flex">
                  <v-overlay
                    absolute
                    :value="true"
                    :z-index="draging ? 5 : -1"
                    :opacity="draging ? 0.46 : 0"
                  />

                  <input
                    hidden
                    ref="file"
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    @change="setFile"
                  />
                  <v-col v-if="_.isEmpty(file[0])" class="text-center my-auto">
                    <div class="mb-8">
                      <v-btn
                        color="#F05326"
                        elevation="0"
                        outlined
                        @click="$refs.file.click()">
                        Pilih berkas
                      </v-btn>
                    </div>
                    <div v-if="!draging" class="subtitle-1 mb-4">
                      Atau tarik file ke sini
                    </div>
                    <div v-else class="subtitle-1 mb-r">Lepas file di sini</div>
                  </v-col>

                  <v-col v-else>
                    <v-img contain aspect-ratio="2" class="rounded" :src="file[0].url">
                      <v-col class="text-right">
                        <v-btn
                          color="white"
                          x-small
                          fab
                          elevation="0"
                          @click="$refs.file.click()">
                          <v-icon>mdi-undo-variant</v-icon>
                        </v-btn>
                      </v-col>

                      <template slot="placeholder">
                        <v-row
                          class="fill-height"
                          no-gutters
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            color="#F05326">
                          </v-progress-circular>
                        </v-row> 
                      </template>
                    </v-img>
                  </v-col>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <!-- FORNEXT: make expand transition -->
          <div
            class="col-md-4 col-12 pa-0"
            v-if="file[0] && (file[0].url || file[0].public_url)">
            <v-col class="white pa-0 fill-height-md overflow-auto">
              <div class="d-md-flex">
                <v-divider class="hidden-md-and-up"></v-divider>
                <v-divider class="hidden-sm-only" vertical></v-divider>
                <div class="pa-6">
                  <div class="title mb-2">Information media</div>
                    <v-text-field
                      class="py-3"
                      :readonly="isReadOnly"
                      outlined
                      dense
                      color="#F05326"
                      label="Title"
                      hide-details="auto"
                      autocomplete="off"
                      v-model="file[0].title">
                    </v-text-field>
                    <v-text-field
                      class="py-3"
                      :readonly="isReadOnly"
                      dense
                      outlined
                      color="#F05326"
                      label="Caption"
                      hide-details="auto"
                      autocomplete="off"
                      v-model="file[0].caption">
                    </v-text-field>
                    <v-text-field
                      class="py-3"
                      :readonly="isReadOnly"
                      dense
                      outlined
                      color="#F05326"
                      label="Alt"
                      hide-details="auto"
                      autocomplete="off"
                      v-model="file[0].alt">
                    </v-text-field>
                    <v-textarea
                      class="py-3"
                      :readonly="isReadOnly"
                      outlined
                      dense
                      hide-details="auto"
                      label="Description"
                      rows="3"
                      no-resize
                      color="#F05326"
                      v-model="file[0].description">
                    </v-textarea>
                  <v-row>
                    <v-col>
                      <v-btn
                        color="#F05326"
                        elevation="0"
                        class="white--text"
                        @click="setAttachment(file[0])"
                        :loading="$store.state.process.run">
                        Lanjut
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="mt-5">

      <Breadcrumbs :title="`Edit Activity`" :items="items"/>
      <Snackbar ref="snackbar"/>

      <div>
        <ValidationObserver ref="observer">
          <v-form>
            <!-- DRAG AND DROP -->
            <div v-if="file[0]" class="text-right mb-2 py-0">
              <v-btn color="#d31145" icon depressed small @click="removeFile()">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>

            <div :class="['dropZone', dragging ? 'dropZone-over' : 'cursor-pointer']" @click="dialog.attachment = true;fetchRecents()">
              <div v-if="form.image_url == ''" class="dropZone-info">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <div class="dropZone-upload-limit-info">
                  <div class="text-center mt-1">
                    <v-btn width="150" depressed dense>
                      Browse File
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-else class="dropZone-info">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <v-img
                  aspect-ratio="4"
                  contain
                  class="mx-auto"
                  :src="form.image_url">
                </v-img>
              </div>
            </div>

            <v-scroll-y-transition>
              <v-alert
                dense
                outlined
                text
                dismissible
                icon="mdi-alert-circle-outline"
                color="#e74c3c"
                class="mb-1 mt-5"
                v-show="error.image_url.length > 0">
                <div class="subtitle-2">{{ error.image_url }}</div>
              </v-alert>
            </v-scroll-y-transition>

            <!-- FORM INPUT -->
            <div class="mt-5">
              <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  v-model="form.title"
                  :error-messages="errors"
                  label="Title"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider name="Short Description" rules="required" v-slot="{ errors }">
                <v-textarea
                  outlined
                  dense
                  rows="3"
                  v-model="form.description"
                  autocomplete="off"
                  :error-messages="errors"
                  label="Short Description"
                  color="#F05326">
                </v-textarea>
              </ValidationProvider>
              <ValidationProvider name="Max Question" rules="required|min_1:1" v-slot="{ errors }">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  autocomplete="off"
                  :error-messages="errors"
                  v-model="form.max_question"
                  label="Max Question"
                  color="#F05326">
                </v-text-field>
              </ValidationProvider>


              <div>
                <div class="subtitle-2 mb-4">
                  Upload Document Activity
                </div>

                <div v-if="filePDF[0] || form.pdf != ''" class="text-right mb-2 py-0">
                  <v-btn color="#d31145" icon depressed small @click="removeFilePDF()">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>

                <div :class="['dropZone-pdf', draggingPDF ? 'dropZone-over' : 'cursor-pointer']"
                  @dragenter="draggingPDF = true"
                  @dragleave="draggingPDF = false">

                  <div v-if="!filePDF[0] && form.pdf == ''" class="dropZone-info" @drag="onChangePDF">
                    <span class="fa fa-cloud-upload dropZone-title"></span>
                    <span class="dropZone-title">
                      Drop file or click to upload file
                    </span>
                    <div class="dropZone-upload-limit-info">
                      <div class="text-center mt-1">
                        <v-btn width="150" depressed dense>
                          Browse File
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <div v-else class="dropZone-info">
                    <span class="fa fa-cloud-upload dropZone-title"></span>
                    <v-img
                      aspect-ratio="6"
                      contain
                      class="mx-auto"
                      :src="require('@/assets/img/pdf_logo.png')">
                    </v-img>
                    <span v-if="filePDF[0]" class="font-weight-bold black--text">
                      {{ fileUploadPDF[0].name }}
                    </span>
                  </div>
                  <form ref="fileUploaded">
                    <input
                      accept="application/pdf"
                      ref="inputFile"
                      type="file"
                      @change="onChangePDF"
                    />
                  </form>
                </div>
              </div>

              <v-switch
                label="Randomize Question"
                color="#F05326"
                inset
                v-model="form.random_question">
              </v-switch>
               <v-switch
                :label="form.is_pdf_downloadable ? 'Download PDF Active' : 'Download PDF Non-Active'"
                color="#F05326"
                inset
                v-model="form.is_pdf_downloadable">
              </v-switch>
            </div>

            <div class="mt-7 mb-15 float-right">
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize black--text mr-5"
                @click="$router.push(`/activity/${$route.params.id_topic}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize white--text"
                @click="saveData"
                color="#F05326">
                Save
              </v-btn>
            </div>

          </v-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put , destroy, get_media, upload } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  data() {
    return {
      searching: "",
      dialog:{
        attachment: false
      },
      tab: 2,
      file: [],
      selected: null,
      url: "",
      filepreview: {
        media_data: {}
      },
      recents: [],
      recent: {},
      errors: {},
      draging: false,
      /**
       * Attachment
       */
      dragging: false,
      // file: '',
      filepreview: '',
      fileUpload: [],
      /** 
       * PDF UPLOAD
       * */ 
      filePDF: [],
      fileUploadPDF: [],
      draggingPDF: false,
      /** 
       * PDF UPLOAD
       * */
      activity_type: [],
      items: [
        {
          text: '',
          disabled: false,
          href: `/activity/${this.$route.params.id_topic}`,
        },
        {
          text: 'Edit Activity',
          disabled: true,
          href: '/greetings/update',
        }
      ],
      form: {
        title: "",
        description: "",
        image_url: "",
        max_question: "",
        random_option: false,
        random_question: true,
        pdf: "",
        is_pdf_downloadable: ""
      },
      error: {
        image_url: "",
      },
      process: {
        run: false
      },
      list:{},
      id_topic:''
    }
  },
  watch: {
    tab() {
      if (this.tab == 0) this.setFile(this.selected);
    },
    selected() {
      if (this.tab == 0) this.setFile(this.selected);
    },
  },
  computed:{
    isReadOnly() {
      return this.tab == 0 || this.$store.state.process.run || this.$store.state.process.run;
    },
  },
  components: { Breadcrumbs, Snackbar },
  created(){
    // this.fetchDetailTopic()
    this.fetchDetail()
    this.fetchList()
  },
  mounted(){
    this.fetchRecents()
  },
  methods:{
    fetchDetailTopic(){
      get(`api/v1/topic/detail/${this.$route.params.id_topic}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.items[0].text = res.data.topic_name
          this.labels = res.data.topic_name
        }
      })
    },
    async fetchList(){
      await get("api/v1/topic/list",{params : {limit:9999}}).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.list = res.data.list
          }
        });
    },
    async fetchDetail(){
      this.$store.state.process.run = true
      await get(`api/v1/activity/detail/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.$store.state.process.run = false
          this.form = {
            title: res.data.title,
            activity_type: res.data.activity_type,
            description: res.data.short_desc,
            max_question: parseInt(res.data.max_question),
            random_question: res.data.is_randomize_question,
            random_option: res.data.is_randomize_option,
            is_pdf_downloadable: res.data.is_pdf_downloadable,
            image_url: res.data.image,
            pdf: res.data.pdf
          }
          this.filepreview = res.data.image
        }else{
          this.$store.state.process.run = false
        }
      })
    },
    async fetchRecents() {
			this.$store.state.process.run = true
			let limit = 9 + this.recent.limit || 9;
			await get_media(`storage/v1/media`,{
        params: {
          title: this.searching,
          limit,
          // page,
          type: "image", 
          sort: 'created_at', 
          dir: 'desc' 
        },
      }).then((response) => {
				let res = response.data
				if (res.status == 200) {
					this.$store.state.process.run = false
					this.recent = res.data;
					this.recents = res.data.list;
				}
			});
      this.show = true;
    },
    setFile($event) {
      let vm = this;
      let src = {
        url: "",
        title: "",
        alt: "",
        description: "",
        caption: "",
      };

      switch (vm.tab) {
        // From recent
        case 0:
          Object.assign(src, this.recents[$event]);
          vm.$set(vm.file, 0, src);
          // console.log(this.file);
          break;
        // From url
        case 1:
          var img = new Image();
          if (!$event) {
            vm.errors = {};
            vm.$set(vm.file,0, {});
            return;
          }

          this.loading = true;
          img.src = $event;
          img
            .decode()
            .then(() => {
              vm.errors = {};
              // src.url = img.src;
              src.url = img.src;
              src.title = img.src.split("/").pop();
              src.alt = img.alt;
              src.type = "image";
              src.source = "url";
              vm.$set(vm.file,0, src);
              this.loading = false;
            })
            .catch((error) => {
              vm.errors = error;
              vm.$set(vm.file, 0, {});
              this.loading = false;
            });
          break;
        // From file
        case 2:

          if (this.draging) {
            this.draging = false;
            src.url = URL.createObjectURL($event.dataTransfer.files[0]);
            src.title = $event.dataTransfer.files[0].name;
            src.alt = $event.dataTransfer.files[0].name;
            src.file = $event.dataTransfer.files[0];
          } else {
            src.url = URL.createObjectURL($event.target.files[0]);
            src.title = $event.target.files[0].name;
            src.alt = $event.target.files[0].name;
            src.file = $event.target.files[0];
          }
          src.source = "upload";
          vm.$set(vm.file, 0, src);
          break;
        default:
          break;
      }
    },
    setAttachment(file) {
      // FIXME: Masih bisa upload berbagai macam file
      if (this.tab || file.description == "file-pdf") {
        this.saving = true;
				this.$store.state.process.run = true
        let formData = new FormData()
        formData.append('source',file.source)
        formData.append('alt',file.alt)
        formData.append('caption', file.caption)
        formData.append('description', file.description)
        formData.append('file', file.file)
        formData.append('title',`${file.title}`)
        formData.append('url', file.url)

        upload("storage/v1/media/upload", {
          data: formData
        }).then((response) => {
					let res = response.data
					if (res.status == 200) {
            this.saving = false;
						this.$store.state.process.run = false
            // console.log(file);
            if (file.description == "file-pdf") {
              this.form.pdf = res.data.public_url
            }else {
              file = { with_media: true, _id: res.data.id, media: res.data };
              this.form.image_url = file.media.public_url
            }
            // console.log(this.form.image_url);
            this.filepreview = file
            this.close();
					}
				}).catch((error) => {
            this.errors.message = error.message;
            this.saving = false;
						this.$store.state.process.run = false
				});
      } else {
        file = { with_media: !this._.isEmpty(file), _id: file.id, media: file };
        this.form.image_url = file.media.public_url
        // console.log(this.form.image_url);
        this.filepreview = file
        this.close();
      }
    },
    close() {
      this.$delete(this.file, 1);
      this.$delete(this.file, 2);
      this.show = false;
      this.recents = [];
      this.recent = {};
      this.selected = null;
      this.url = "";
      this.dialog.attachment = false
    },
    removeFile(){
      this.show = false;
      this.recents = [];
      this.file = []
      this.recent = {};
      this.selected = null;
      this.url = "";
    },
    onChange(e) {
      let src = {
        src: null,
        title: null,
        alt: null,
        description: null,
        caption: null,
      };
      var files = e.target.files || e.dataTransfer.files;
      let selectedFiles = e.target.files[0] || e.dataTransfer.files[0]

      if (this.draging) {
        this.draging = false;
        this.filepreview = URL.createObjectURL(selectedFiles);
        src.src = URL.createObjectURL(selectedFiles);
        src.title = selectedFiles.name;
        src.alt = selectedFiles.name;
      } else {
        this.filepreview = URL.createObjectURL(e.target.files[0]);
        src.src = URL.createObjectURL(e.target.files[0]);
        src.title = e.target.files[0].name;
        src.alt = e.target.files[0].name;
      }
      
      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.fileUpload = files
    },
    /** 
    * HANDEL PDF UPLOAD
   */
    removeFilePDF(){
      this.filePDF = []
      this.fileUpload = []
      this.draggingPDF = false;
      this.$refs.fileUploaded.reset();
      this.$refs.inputFile.value = null;
      this.form.pdf = ''
    },
    onChangePDF(e) {
      var files = e.target.files || e.dataTransfer.files;
      let selectedFiles = e.target.files[0] || e.dataTransfer.files[0];

      if (!files.length) {
        this.draggingPDF = false;
        return;
      }

      // console.log(e.target.files);

      let src = {
        url: "",
        title: "",
        alt: "",
        description: "file-pdf",
        caption: "",
        file: "",
        source: ""
      };

      if (this.draging) {
        this.dragingPDF = false;
        src.url = URL.createObjectURL(e.dataTransfer.files[0]);
        src.title = e.dataTransfer.files[0].name;
        src.alt = e.dataTransfer.files[0].name;
        src.file = e.dataTransfer.files[0];
      } else {
        src.url = URL.createObjectURL(e.target.files[0]);
        src.title = e.target.files[0].name;
        src.alt = e.target.files[0].name;
        src.file = e.target.files[0];
      }

      src.source = "upload";
      this.$set(this.filePDF, 0, src);

      // console.log(this.filePDF);
      // return

      if (files[0].name.split(".").pop() !== "pdf") {
        this.filePDF = "";
        this.filePDF = [];
        this.fileUploadPDF = [];
        this.dialog.errorUpload = true;
        this.$refs.snackbar.open("error", "Silahkan pilih file dengan format .pdf");
        this.draggingPDF = false;
        this.$refs.fileUploaded.reset();
        this.$refs.inputFile.value = null;
        return;
      }

      if (files[0].size > 5081025) {
        this.filePDF = "";
        this.filePDF = [];
        this.fileUploadPDF = [];
        this.dialog.errorUpload = true;
        this.$refs.snackbar.open("error", "File PDF terlalu besar, minimum file size adalah 2 MB");
        this.draggingPDF = false;
        this.$refs.fileUploaded.reset();
        this.$refs.inputFile.value = null;
      }

      this.setAttachment(this.filePDF[0])

      this.fileUploadPDF = files;
    },
    /** 
      * HANDEL PDF UPLOAD
    */
    validation() {
      var error = this.error,
        valid = true;

      if (this.form.image_url === "") {
        error.image_url = "Gambar Tidak Boleh Kosong";
        valid = false;
      } else {
        error.image_url = "";
      }
      return valid;
    },
    async saveData(){
      this.$store.state.process.run = true
      // console.log(this.id_topic)
      const isValid = await this.$refs.observer.validate()
      const Valids = await this.validation()
      if (isValid && Valids) {
        // if (this.id_topic ==='') {
          await put(`api/v1/activity/update/${this.$route.params.id_topic}/${this.$route.params.id_activity}`,{
            data: {
              title: this.form.title,
              short_desc: this.form.description,
              image: this.form.image_url,
              max_question: this.form.max_question,
              is_randomize_question: this.form.random_question,
              // move_to:this.id_topic,
              is_randomize_option: false,
              pdf: this.form.pdf,
              is_pdf_downloadable: this.form.is_pdf_downloadable
            }
          })
          .then((response) => {
            let res = response.data
            if (res.status == 200) {
              this.$refs.snackbar.open("#4CAF50", `New Activity Updated Succesfully`);
              setTimeout(() => {
                this.$store.state.process.run = false
                this.$router.push(`/activity/${this.$route.params.id_topic}`)
              }, 1000);
            }else{
              this.$store.state.process.run = false
              this.$refs.snackbar.open("error", `New Activity Updated Failed`);
            }
          })
        // }else{
        //   await post(`api/v1/activity/add/${this.id_topic}`,{
        //     data: {
        //       title: this.form.title,
        //       short_desc: this.form.description,
        //       image: this.form.image_url,
        //       max_question: this.form.max_question,
        //       is_randomize_question: this.form.random_question,
        //       is_randomize_option: false,
        //     }
        //   })
        //   .then(() => {
        //     destroy(`api/v1/activity/delete/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
        //       .then((response) => {
        //         let res = response.data
        //         if (res.status == 200) {
        //           this.$store.state.dialog.confirmDelete = false
        //           this.$store.state.process.run = false
        //           this.$refs.snackbar.open("#4CAF50", `Activity Moved Succesfully`);
        //           this.$router.push(`/activity/${this.$route.params.id_topic}`)
        //         }else{
        //           this.$store.state.dialog.confirmDelete = false
        //           this.$store.state.process.run = false
        //           this.$refs.snackbar.open("error", `Activity Moved Failed`);
        //         }
        //       })
        //   })
        // }
      }else {
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `Form not valid`);
      }
    },
    async save() {
      this.$store.state.process.run = true
      let image = this.fileUpload[0]
      if(this.fileUpload.length > 0){
        let formData = new FormData()
        formData.append('source','upload')
        formData.append('file', this.fileUpload[0])
        formData.append('title',`Gambar ${this.form.title}`)
        this.$store.state.process.run = true
        await upload(`storage/v1/media/upload`,{
          data: formData
        })
        .then((response) => {
          let res = response.data
          if(res.status == 200){
            let image_id = res.data.id
            this.form.image_url = res.data.public_url
            let image_title = res.data.title
            let type_file = res.data.type
            this.$refs.snackbar.open("#4CAF50", `Image Uploaded Succesfully`);
            this.saveData();
          }else{
            this.$refs.snackbar.open("error", `Image Uploaded Failed`);
          }
        })
      }else{
        this.saveData()
      }
    }
  }
}
</script>

<style>
.dropZone {
  width: 100%;
  min-height: 300px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}
.dropZone-pdf {
  width: 100%;
  min-height: 200px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #F05326;
}

.dropZone:hover .dropZone-title {
  color: #F05326;
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-pdf input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}


.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>